<script setup lang="ts">
import { computed } from 'vue';
import { flattenConnection, getOptions } from './helpers';
import type { PreVariantSelected, Product } from './MultiProductModal.vue';
import type { ProductOption } from '../../type/common';
import type { ProductVariant } from './types';

const props = withDefaults(
  defineProps<{
    item: Product;
    variants: PreVariantSelected;
    list?: Product[];
    option?: ProductOption;
    optionIdx?: number;
  }>(),
  {
    optionIdx: 0,
  },
);

const emits = defineEmits<{
  (e: 'handleChangeVariant', optionValue?: string): void;
}>();

const variants = computed(() => flattenConnection(props.item?.variants) as ProductVariant[]);

const getValueByOption = (optionName: any, productId: string) => {
  const item = props.list?.find((el) => el.id === productId);
  if (!item) return;
  const option = props.variants?.[productId]?.optionSelected;
  if (!option?.[optionName]) return item.options?.find((el) => el.name === optionName)?.values[0].label;
  const variantId = props.variants?.[productId]?.variantId;
  const title = variants.value.find((el) => el.id === variantId)?.title;
  return title?.split(' / ')[props.optionIdx];
};

const options = computed(() => {
  return getOptions(props?.option, props.variants[props.item.id]?.optionSelected, variants.value);
});
const value = computed(() => getValueByOption(props.option?.name, props.item?.id));
</script>

<template>
  <GSelect
    id="option"
    :options="options"
    :active-id="value"
    :is-light-mode="true"
    select-btn-classes="!max-w-[138px]"
    lazy-list-classes="!max-h-[180px]"
    btn-content-classes="!w-full"
    placement="bottom-start"
    @select="(value) => emits('handleChangeVariant', value)">
    <template #button-content>
      <p class="whitespace-nowrap">{{ option?.name }}: {{ value }}</p>
    </template>
  </GSelect>
</template>
